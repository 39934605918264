import './Faq.css';

function Faq() {    return (
        <div className="faq">
            <h1 className="faqh1">FAQ</h1>
            <div className="container">
                <details className="faqdetails">
                    <summary className="faqsummary">what ıs $MANCE</summary>
                    <div>
                        <p className="faqp">
                            MANCE is a meme-based token produced on Binance Smart Chain. Our job is just entertainment.
                            Have fun and win.</p>
                    </div>
                </details>
            </div>
            <div className="container">
                <details className="faqdetails">
                    <summary className="faqsummary">Do you have a project?</summary>
                    <div>
                        <p className="faqp">We have no promises. Life is short, your earnings are high.
                            Enjoy Meme, love cats and spend your earnings with pleasure.</p>
                    </div>
                </details>
            </div>
            <div className="container">
                <details className="faqdetails">
                    <summary className="faqsummary">how to buy $MANCE</summary>
                    <div>
                        <p className="faqp">
                            Make sure that you have a BEP20 wallet and it's filled with money.</p>
                    </div>
                </details>
            </div>
            <div className="container">
                <details className="faqdetails">
                    <summary className="faqsummary">Is there any tax?</summary>
                    <div>
                        <p className="faqp">There is no such shit. We came here to have fun and are we going to take tax from you? Fuck it dude.</p>
                    </div>
                </details>
            </div>
        </div>
    )
}

export default Faq;
