import './About.css';
import img from "../img/page2img.svg"
function About() {
    return (
        <div className="page2">
            <div className="page2imgdiv">
                <img className="page2img" src={img} alt=""/>
            </div>
            <div className="page2textdiv">
                <h1 className="page2h1">
                    Welcome to $MANCESYSTEM
                </h1>
                <h3>
                    Memenance is a meme-based token produced on the Binance Smart Chain. Our job is just entertainment. Have fun and win.                </h3>
            </div>
        </div>
    );
}

export default About;
