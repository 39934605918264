import './Project.css';
import img1 from "../img/MEMENANCE_Website (1).svg"
import img2 from "../img/MEMENANCE_Website (2).svg"
import img3 from "../img/MEMENANCE_Website (3).svg"
import img4 from "../img/MEMENANCE_Website (4).svg"
import img5 from "../img/MEMENANCE_Website (6).svg"
import img6 from "../img/MEMENANCE_Website (7).svg"
import img7 from "../img/MEMENANCE_Website (8).svg"
import img8 from "../img/MEMENANCE_Website (9).svg"
import img9 from "../img/MEMENANCE_Website (10).svg"

function Project() {
    return (
        <div className="project">
            <div className="projectimg">
                <img className="projeboxcenter" src={img5} alt=""/>
            </div>
            <div className="projectbutton">
                <h1 className="projecta">for more, ask on</h1>
                <a href="https://t.me/memenancechat" target="_blank" className="projecta1"><h1>T E L E G R A M</h1></a>
            </div>
        </div>
    );
}

export default Project;
