import './Footer.css';
import img from "../img/cat400px.svg"
import img1 from "../img/moon400px.svg"
import { RiTwitterXLine } from "react-icons/ri";
import { FaTelegramPlane } from "react-icons/fa";
import { IoMdMail } from "react-icons/io";

function Footer() {
    return (
        <div className="footer">
            <div className="footerleft">
                <img className="catimg" src={img} alt=""/>
            </div>
            <div className="footercenter">
                <div className="footertop"><h1>$MANCEMEDIA</h1></div>
                <div className="footerbottom">
                    <h1>joın our communıty to the moon! </h1><br/>
                    <a href="https://twitter.com/memenancetoken" target="_blank"><RiTwitterXLine className="footericon"/></a>
                    <a href="https://t.me/memenancechat" target="_blank"><FaTelegramPlane className="footericon"/></a>
                    <a href="mailto:info@memenance.com" target="_blank"><IoMdMail className="footericon"/></a>
                </div>
            </div>
            <div className="footerright">
                <img className="moonimg" src={img1} alt=""/>
            </div>
        </div>
    );
}

export default Footer;
