import './Intro.css';
import img from "../img/introimg.svg"

function App() {
    return (
        <div className="intro">
            <div className="introbutton" >

                <a onClick={()=> alert("Don't hurry, We will make announcement, Stay tuned, Don't miss!")} href="" style={{textDecoration:'none'}}><div className="introbtn" >BUY $MANCE</div></a>

                </div>
            <div className="introimgdiv">
                <img className="introimg" src={img} alt=""/>
            </div>
            <div>
                <p className="marquee">
                    <span className="introspan">$MANCE - $MANCE - $MANCE - $MANCE - $MANCE - $MANCE - $MANCE - $MANCE - $MANCE - $MANCE - $MANCE - $MANCE - $MANCE;</span>
                </p>

            </div>
        </div>
    );
}

export default App;
