import './Tokeneconomics.css';
import img from "../img/MEMENANCE_Website (600 x 600 piksel).svg"
function Tokeneconomics() {

    return (
        <div className="tokeneconomics">
            <div className="tokeneconomicstext">
                <h1 style={{}}>$manceconomıc</h1>
                <h1>TOTAL SUPPLY: 1,000,000,000 $MANCE</h1>
            </div>
            <div className="surce">
                <div className="pie animate no-round" style={{}}>
                    <h4 > 55% <br/>airdrop & marketing</h4>
                </div>
                <div className="pie1 animate no-round1" style={{}}>
                    <h4 > 25% <br/> exchange</h4>
                </div>
                <div className="pie2 animate2 no-round2" style={{}}>
                    <h4 > 15% <br/> liquidity</h4>
                </div>
                <div className="pie3 animate3 no-round3" style={{}}>
                    <h4 > 5% <br/> team</h4>
                </div>
            </div>
            <a href="https://bscscan.com/token/0xc3d6889F939A4ddcF5af7B546b7F97765F0F005a" target="_blank" style={{textDecoration:'none'}}>
                <div className="button">
                        $mance address
                </div>
            </a>
            <div className="tokeneconomicsBottom">
                <img className="tokeneconomicsimg" src={img} alt=""/>
                <h1 className="tokeneconomicsh1">
                    THE CAT OF BINANCE <br/>
                    Who said this year is the year of the dog? This year is the year of the CAT, the YEAR OF MEMENANCE!
                </h1>
            </div>
        </div>
    );
}

export default Tokeneconomics;
