import './Roadmap.css';
import img from "../img/roadmapimg1.svg"
import img2 from "../img/MEMENANCE_Website (250 x 250 piksel) (2).svg"
import img3 from "../img/MEMENANCE_Website (250 x 250 piksel) (3).svg"
import img4 from "../img/MEMENANCE_Website (250 x 250 piksel) (4).svg"
import img5 from "../img/MEMENANCE_Website (250 x 250 piksel) (5).svg"
import img6 from "../img/MEMENANCE_Website (250 x 250 piksel) (6).svg"

function Roadmap() {
    return (
        <div className="roadmap">
            <h1 style={{color:'yellow'}}>$mancemap</h1>
            <div className="timeline">
                <div className="timeline__event  animated fadeInUp delay-3s timeline__event--type1">
                    <div className="timeline__event__icon ">
                        <img src={img} alt=""  className="lni-cake"></img>
                    </div>
                    <div className="timeline__event__date">
                        Station 1
                    </div>
                    <div className="timeline__event__content ">
                        <div className="timeline__event__title">
                            THE BIRTH OF MEMENANCE
                        </div>
                    </div>
                </div>
                <div className="timeline__event animated fadeInUp delay-2s timeline__event--type2">
                    <div className="timeline__event__icon">
                        <img src={img2} alt=""  className="lni-cake"></img>
                    </div>
                    <div className="timeline__event__date">
                        Station 2
                    </div>
                    <div className="timeline__event__content">
                        <div className="timeline__event__title">
                            $mance communıty
                            ıs growıng
                        </div>
                    </div>
                </div>
                <div className="timeline__event animated fadeInUp delay-1s timeline__event--type3">
                    <div className="timeline__event__icon">
                        <img src={img3} alt=""  className="lni-cake"></img>
                    </div>
                    <div className="timeline__event__date">
                        Station 3
                    </div>
                    <div className="timeline__event__content">
                        <div className="timeline__event__title">
                            $MANCE WORKS
                            FOR COMMUNITY
                        </div>
                    </div>
                </div>
                <div className="timeline__event animated fadeInUp timeline__event--type1">
                    <div className="timeline__event__icon">
                        <img src={img4} alt=""  className="lni-cake"></img>
                    </div>
                    <div className="timeline__event__date">
                        Station 4
                    </div>
                    <div className="timeline__event__content">
                        <div className="timeline__event__title">
                            $mance IS GETTING READY FOR THE GRAND LAUNCH
                        </div>

                    </div>
                </div>

                <div className="timeline__event animated fadeInUp delay-1s timeline__event--type2">
                    <div className="timeline__event__icon">
                        <img src={img5} alt=""  className="lni-cake"></img>
                    </div>
                    <div className="timeline__event__date">
                        Station 5
                    </div>
                    <div className="timeline__event__content">
                        <div className="timeline__event__title">
                            GRAND LAUNCH BEGINS WITH $MANCE!
                        </div>

                    </div>
                </div>
                <div className="timeline__event animated fadeInUp delay-1s timeline__event--type3">
                    <div className="timeline__event__icon">
                        <img src={img6} alt=""  className="lni-cake"></img>
                    </div>
                    <div className="timeline__event__date">
                        Station 6
                    </div>
                    <div className="timeline__event__content">
                        <div className="timeline__event__title">
                            $MANCE AND COMMUNITY ARE THE WINNERS
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default Roadmap;
